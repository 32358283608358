import { mergeDeepRight } from './mergeDeepRight';

type Translation = Record<PropertyKey, any>;

export type Translations<T = Record<string, unknown>> = T & {
  _translations?: Record<string, Translation>;
};

export function translate<T extends Record<string, any>>(data: Translations<T>, locale?: string): T {
  /**
   * The document hasn't been translated yet, return as is.
   */
  if (data['_translations'] === undefined) {
    return data;
  }

  let translation: Translation;

  if (locale != null) {
    /**
     * For a BCP 47 tag such as `nan-Hant-TW`, we want to search for translations in the most specific to least specific order:
     *
     * 1. nan-Hant-TW
     * 2. nan-Hant
     * 3. nan
     */
    const subtags = locale.split('-');

    for (let i = subtags.length; i > 0; i--) {
      const tag = subtags.slice(0, i).join('-');
      if (data['_translations'][tag] !== undefined) {
        translation = data['_translations'][tag];

        if (translation != null) {
          return mergeDeepRight(data, translation);
        }
      }
    }
  }

  return data;
}
