import type { PartialKeys } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Untrackable } from '../../abstract/Document';
import { getFirestore } from '../../firebase/firestore';
import { Countries, type Country } from '../Countries';
import type { IGroup } from './Groups.types';
import { isIndexedDBAvailable } from '@firebase/util';
import { getDocsFromCache, loadBundle, namedQuery, type Query } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

/**
 * Country-overloaded `IGroup`.
 */
export type IGroupRequired = PartialKeys<Required<IGroup>, 'articles' | 'image' | 'intro' | 'supplierType' | 'tip'>;

@Identifiable
@Untrackable
export class Group extends AbstractDocument<IGroup> {
  readonly collections = {};

  get(data: false): never;
  get(data?: true): Promise<IGroup>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.reference.parent.parent.id !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Groups.getById(result.id).get(true), result) as IGroup;
    }

    return result;
  }
}

export class Groups extends AbstractCollection<Group, IGroup> {
  static definitions = {
    _: {} as IGroup,
  };

  static path = 'groups';

  constructor(document: Country) {
    super(document.collection(Groups.path), Group);
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result: Record<string, IGroupRequired> = {};

    const country = this.reference.parent.id;
    const queryName = `${country}-groups`;

    try {
      const firestore = getFirestore();

      /**
       * Checks for `globalThis.indexedDB` availability, only present in the browser.
       */
      if (isIndexedDBAvailable() !== true) {
        throw new Error(`IndexedDB is not available.`);
      }

      let query = (await namedQuery(firestore, queryName)) as Query<IGroupRequired>;

      /**
       * If the named query is not yet loaded, fetch the data bundle data from the server.
       */
      if (query == null) {
        const data = await fetch(`/api/countries/${country}/seed?v=2`);

        if (data.ok === true) {
          await loadBundle(firestore, data.body);
        }

        query = (await namedQuery(firestore, queryName)) as Query<IGroupRequired>;

        if (query == null) {
          throw new Error(`Unable to load Firestore query '${queryName}' from local cache.`);
        }
      }

      const documents = (await getDocsFromCache(query)).docs;

      for (const document of documents) {
        result[document.id] = document.data();
      }
    } catch (error) {
      result = await this.query<IGroupRequired>().get(true);
    }

    if (country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Groups.all(), result) as Record<string, IGroupRequired>;
    }

    return result;
  }
}
