import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import type { ISupplier_Type } from '../Suppliers.types';
import { type Wedding, Weddings } from '../Weddings';
import { PreferenceCakes } from './Preferences/cakes';
import type { IPreferenceCakes } from './Preferences/cakes.types';
import { PreferenceFlorist } from './Preferences/florist';
import type { IPreferenceFlorist } from './Preferences/florist.types';
import { PreferencePhoto } from './Preferences/photo';
import type { IPreferencePhoto } from './Preferences/photo.types';
import { PreferenceVenue } from './Preferences/venue';
import type { IPreferenceVenue } from './Preferences/venue.types';
import { PreferenceVideo } from './Preferences/video';
import type { IPreferenceVideo } from './Preferences/video.types';
import type { WriteBatch } from 'firebase/firestore';

type Collections = IPreferenceCakes | IPreferenceFlorist | IPreferencePhoto | IPreferenceVenue | IPreferenceVideo;

@Identifiable
@Timestampable
@Untrackable
export class Preference extends AbstractDocument<Collections> {
  readonly collections = {};
}

export class Preferences extends AbstractCollection<Preference, Collections> {
  static definitions = {
    _: {} as Collections,
  };

  static path = 'preferences';

  constructor(document: Wedding) {
    super(document.collection(Preferences.path), Preference);
  }

  getById(key: ISupplier_Type, batch?: WriteBatch) {
    let document;

    switch (key) {
      case 'cakes':
        document = new (AbstractCollection as any)(this.reference, PreferenceCakes);
        break;

      case 'florist':
        document = new (AbstractCollection as any)(this.reference, PreferenceFlorist);
        break;

      case 'photo':
        document = new (AbstractCollection as any)(this.reference, PreferencePhoto);
        break;

      case 'venue':
        document = new (AbstractCollection as any)(this.reference, PreferenceVenue);
        break;

      case 'video':
        document = new (AbstractCollection as any)(this.reference, PreferenceVideo);
        break;

      default:
        throw new Error('Unhandled supplier type.');
    }

    return document.getById(key, batch);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
