import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { ICost } from './Costs.types';

@Identifiable
@Timestampable
@Untrackable
export class Cost extends AbstractDocument<ICost> {
  readonly collections = {};
}

export class Costs extends AbstractCollection<Cost, ICost> {
  static definitions = {
    _: {} as ICost,
  };

  static path = 'costs';

  constructor(document: Wedding) {
    super(document.collection(Costs.path), Cost);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }

  /**
   * Removes `Wedding.budget` and `Wedding.costs` and drops all documents in the costs sub-collection.
   */
  reset() {
    return Promise.all([this.wedding.remove('budget', 'costs'), this.delete(true)]);
  }

  /**
   * @deprecated Use `reset()` instead.
   */
  resetCosts() {
    return this.reset();
  }
}
