// @ts-ignore Ignoring this error until all projects using a high enough version of @types/node
import type { AsyncLocalStorage } from 'async_hooks';
import type { FirebaseApp } from 'firebase/app';
import { type Auth, getAuth as getFirebaseAuth } from 'firebase/auth';
import {
  type Firestore,
  getFirestore as getFirebaseFirestore,
  Bytes as IBytes,
  DocumentReference as IDocumentReference,
  GeoPoint as IGeoPoint,
  Timestamp as ITimestamp,
} from 'firebase/firestore';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace firebase {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace firestore {
    export type Bytes = IBytes;
    export type DocumentReference = IDocumentReference;
    export type GeoPoint = IGeoPoint;
    export type Timestamp = ITimestamp;

    export const Bytes = IBytes;
    export const DocumentReference = IDocumentReference;
    export const GeoPoint = IGeoPoint;
    export const Timestamp = ITimestamp;
  }
}

declare global {
  // eslint-disable-next-line no-var
  var firebaseContext: AsyncLocalStorage<{ app?: string; user?: string; fbApp: FirebaseApp }>;
}

/**
 * Returns the current Firebase app from the global local storage store, which is provided by Node.js `AsyncContext`.
 * This is needed to support per-user context in Firebase on the backend, handling multiple users at the same time, using authed access to Firebase.
 */
export function getApp(): FirebaseApp {
  return globalThis.firebaseContext?.getStore()?.fbApp ?? undefined;
}

export function getAuth(): Auth {
  return getFirebaseAuth(getApp());
}

export function getFirestore(): Firestore {
  return getFirebaseFirestore(getApp());
}

/**
 * Re-exported `CountryCodes` enum from Gazetteer so that we don't have to keep casting it's type.
 */
export type { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

export type { IEncryptionEnvelope } from '../../source/models/Suppliers/Apps/instagram.types';
