import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Untrackable } from '../../abstract/Document';
import { getFirestore } from '../../firebase/firestore';
import { Countries, type Country } from '../Countries';
import type { ITodayTaskFlow } from './TodayTaskFlows.types';
import { isIndexedDBAvailable } from '@firebase/util';
import { getDocsFromCache, loadBundle, namedQuery, type Query, where } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

/**
 * Country-overloaded `ITodayTaskFlows`.
 */
export type ITodayTaskFlowRequired = Required<ITodayTaskFlow>;

@Identifiable
@Untrackable
export class TodayTaskFlow extends AbstractDocument<ITodayTaskFlow> {
  readonly collections = {};

  get(data: false): never;
  get(data?: true): Promise<ITodayTaskFlow>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.reference.parent.parent.id !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').TodayTaskFlows.getById(result.id).get(true), result) as ITodayTaskFlow;
    }

    return result;
  }
}

export class TodayTaskFlows extends AbstractCollection<TodayTaskFlow, ITodayTaskFlow> {
  static definitions = {
    _: {} as ITodayTaskFlow,
  };

  static path = 'today-task-flows';

  constructor(document: Country) {
    super(document.collection(TodayTaskFlows.path), TodayTaskFlow);
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result: Record<string, ITodayTaskFlowRequired> = {};

    const country = this.reference.parent.id;
    const queryName = `${country}-today-task-flows`;

    try {
      const firestore = getFirestore();

      /**
       * Checks for `globalThis.indexedDB` availability, only present in the browser.
       */
      if (isIndexedDBAvailable() !== true) {
        throw new Error(`IndexedDB is not available.`);
      }

      let query = (await namedQuery(firestore, queryName)) as Query<ITodayTaskFlowRequired>;

      /**
       * If the named query is not yet loaded, fetch the data bundle data from the server.
       */
      if (query == null) {
        const data = await fetch(`/api/countries/${country}/seed?v=2`);

        if (data.ok === true) {
          await loadBundle(firestore, data.body);
        }

        query = (await namedQuery(firestore, queryName)) as Query<ITodayTaskFlowRequired>;

        if (query == null) {
          throw new Error(`Unable to load Firestore query '${queryName}' from local cache.`);
        }
      }

      const documents = (await getDocsFromCache(query)).docs;

      for (const document of documents) {
        result[document.id] = document.data();
      }
    } catch (error) {
      result = await this.query<ITodayTaskFlowRequired>().get(true);
    }

    if (country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').TodayTaskFlows.all(), result) as Record<string, ITodayTaskFlowRequired>;
    }

    return result;
  }

  /**
   * Return all today task flows.
   */
  get() {
    return this.query().get(true);
  }

  /**
   * Return active today task flow.
   */
  get active() {
    return this.query([where('isActive', '==', true)]);
  }
}
