import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { Wedding, Weddings } from '../Weddings';
import { IWeddingFeedback } from './Feedback.types';

@Identifiable
@Timestampable
@Untrackable
export class Feedback extends AbstractDocument<IWeddingFeedback> {
  readonly collections = {};
}

export class FeedbackCollection extends AbstractCollection<Feedback, IWeddingFeedback> {
  static definitions = {
    _: {} as IWeddingFeedback,
  };

  static path = 'feedback';

  constructor(document: Wedding) {
    super(document.collection(FeedbackCollection.path), Feedback);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
