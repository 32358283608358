export function mergeDeepRight(target: any, source: any): any {
  const isObject = (value: any): boolean => value != null && typeof value === 'object' && Array.isArray(value) !== true;

  if (isObject(target) !== true || isObject(source) !== true) {
    /**
     * If neither is an object, return source.
     */
    return source;
  }

  for (const key in source) {
    if (isObject(source[key]) === true) {
      if (target[key] === undefined) {
        target[key] = {};
      }

      target[key] = mergeDeepRight(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }

  return target;
}
