import { AbstractCollection } from '../../abstract/Collection';
import { AbstractQuery } from '../../abstract/Collection/Query';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { Countries } from '../Countries';
import { type Wedding, Weddings } from '../Weddings';
import type { ITask } from './Tasks.types';
import { type DocumentChangeType, orderBy, type Query, query, type QueryConstraint, where } from 'firebase/firestore';
import { mergeDeepRight, splitEvery } from 'ramda';

@Identifiable
@Timestampable
@Untrackable
export class Task extends AbstractDocument<ITask> {
  readonly collections = {};
}

export class Tasks extends AbstractCollection<Task, ITask> {
  static definitions = {
    _: {} as ITask,
  };

  static path = 'tasks';

  public static todayTaskGroupId = '7a1bb849-a468-4313-8231-ac8f5eb7e6b6';

  constructor(document: Wedding) {
    super(document.collection(Tasks.path), Task);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }

  get next() {
    return this.query([where('done', '==', false), orderBy('order', 'asc')]);
  }

  /**
   * Recursively merges the countries seed data, with the wedding data, returning all tasks belonging to a certain group.
   *
   * Only touched (custom, done) tasks by the wedding should be persisted to the wedding tasks collection.
   * This allows the wedding to persist touch tasks, and still be able to update the seed tasks without re-initializing the checklist.
   * It should also offer better performance and lower costs.
   */
  async getByGroup(group: string) {
    const seed = await Countries._.getById(await this.wedding.country).Tasks.getByGroup(group);
    const tasks = await this.query([orderBy('order', 'asc')]).get(true);
    const result: Record<string, ITask> = {};

    for (const key in tasks) {
      if (tasks[key].group !== group) {
        continue;
      }

      result[key] = seed[key] !== undefined ? mergeDeepRight(seed[key], tasks[key]) : tasks[key];
    }

    return result;
  }

  /**
   * Deletes multiple tasks by ID
   */
  async deleteByIds(ids: ITask['id'][]) {
    if (ids.length === 0) {
      return;
    }

    const batches = splitEvery(250, ids);

    for (const batch of batches) {
      this.begin();

      for (const id of batch) {
        await this.getById(id).delete();
      }

      await this.commit();
    }

    return;
  }

  query<R = ITask>(criteria?: Query | QueryConstraint[], on?: DocumentChangeType[]) {
    // firestore requires orderBy when using != query and there are 2 orderBy's used in query
    const findAllTasksExcludingTodayTasks = [orderBy('group'), where('group', '!=', Tasks.todayTaskGroupId)];

    if (criteria == null) {
      criteria = query(this.reference, ...findAllTasksExcludingTodayTasks, orderBy('__name__'));
    } else if (Array.isArray(criteria)) {
      criteria = query(this.reference, ...findAllTasksExcludingTodayTasks, ...criteria);
    }

    return new AbstractQuery<R>(criteria as Query<R>, on);
  }

  queryTodayTasks<R = ITask>(on?: DocumentChangeType[]) {
    const criteria: QueryConstraint[] = [where('group', '==', Tasks.todayTaskGroupId), orderBy('__name__')];

    return new AbstractQuery<R>(query(this.reference, ...criteria) as Query<R>, on);
  }
}
