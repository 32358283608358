import { getFirestore } from '../firebase/firestore';
import type { Constructable } from './_';
import { AbstractQuery } from './Collection/Query';
import { AbstractDocument } from './Document';
import {
  collection,
  CollectionReference,
  doc,
  type DocumentChangeType,
  type DocumentReference,
  getDocs,
  limit,
  orderBy,
  query,
  type Query,
  type QueryConstraint,
  writeBatch,
  type WriteBatch,
} from 'firebase/firestore';
import uuid from 'uuid-random';

// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class AbstractCollection<D extends AbstractDocument<{}>, T> {
  static definitions: Record<string, any> = {};
  static path: string = null;

  readonly document: Constructable<D> = null;
  readonly reference: CollectionReference<T> = null;

  protected batch: WriteBatch = null;

  constructor(reference: string | CollectionReference<T>, document: Constructable<D>) {
    if (typeof reference === 'string') {
      reference = collection(getFirestore(), reference) as CollectionReference<T>;
    }

    this.document = document;
    this.reference = reference;
  }

  /**
   * Basename of the collection.
   */
  get id() {
    return this.reference.id;
  }

  /**
   * Native Firestore instance.
   */
  get db() {
    return this.reference.firestore;
  }

  /**
   * Generic absolute path to this (sub-)collection.
   */
  get namespace() {
    let scope: CollectionReference | DocumentReference = this.reference;

    const result = [];

    while (scope.id != null) {
      if (scope instanceof CollectionReference) {
        result.unshift(scope.id);
      }

      scope = scope.parent;

      if (scope == null) {
        break;
      }
    }

    return result;
  }

  /**
   * Absolute path to this (sub-)collection.
   */
  get path() {
    return this.reference.path;
  }

  /**
   * Acquires a new `WriteBatch` instance for this collection.
   * All write operations (up to a maximum limit of 500) will be postponed until `commit()` is called.
   */
  begin() {
    this.batch = writeBatch(this.db);
  }

  /**
   * Commits all of the writes in the current write batch (if any) as a single atomic unit.
   * Retries to commit the current write batch up to 3 times to work around contention locks.
   */
  async commit() {
    if (this.batch != null) {
      let retries = 0;

      while (retries++ < 3) {
        try {
          await this.batch.commit().then(() => {
            this.batch = null;
          });

          break;
        } catch (error) {
          if (retries === 3) {
            if (error instanceof Error) {
              if (error.message.includes(this.path) !== true) {
                error.message = [`Failed to commit() on '${this.path}'.`, error.message].join('\n');
              }
            }

            throw error;
          }
        }
      }
    }

    return true;
  }

  /**
   * Deletes all documents under this collection and returns how many documents were deleted.
   *
   * @param deep Recursively delete any other sub-collections.
   */
  async delete(deep = true) {
    let result = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const snapshot = await getDocs(query(this.reference, orderBy('__name__'), limit(500)));

      if (snapshot.size === 0) {
        return result;
      }

      const batch = writeBatch(this.db);
      const promises: Promise<number>[] = [];

      for (const document of snapshot.docs) {
        promises.push(this.getById(document.id, batch).delete(deep));
      }

      await Promise.all(promises).then((counts) => {
        for (const count of counts) {
          result += count;
        }

        batch.commit();
      });
    }
  }

  /**
   * Fetches a document by key and returns it as a concrete document instance.
   *
   * @param key The document ID.
   * @param batch An optional `WriteBatch` instance to use (will default to collection-level `batch`).
   */
  getById(key: string, batch?: WriteBatch) {
    if (batch == null) {
      batch = this.batch;
    }

    const target = new this.document(doc(this.reference, key), batch);
    const handlers = {
      get: (target: any, name: number | string | symbol) => {
        if (target.collections[name] !== undefined) {
          return target.collections[name.toString()];
        }

        return target[name];
      },
    };

    return new Proxy(target, handlers) as D & D['collections'];
  }

  /**
   * Creates a new document reference in the collection using a UUID as its key.
   *
   * @param key Optionally specify the key to use.
   */
  push(key?: string) {
    return this.getById(key == null ? uuid() : key);
  }

  /**
   * Returns an "abstract" query instance.
   *
   * @param criteria Defaults to all the documents in the collection, ordered by their ID.
   * @param on Events `('added' | 'removed' | 'modified')[]` to listen to, or all by default.
   */
  query<R = T>(criteria?: Query | QueryConstraint[], on?: DocumentChangeType[]) {
    if (criteria == null) {
      criteria = query(this.reference, orderBy('__name__'));
    } else if (Array.isArray(criteria)) {
      criteria = query(this.reference, ...criteria);
    }

    return new AbstractQuery<R>(criteria as Query<R>, on);
  }
}
