import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { Countries, type Country } from '../Countries';
import type { ICard } from './Cards.types';
import { where } from 'firebase/firestore';

@Identifiable
@Timestampable
@Untrackable
export class Card extends AbstractDocument<ICard> {
  readonly collections = {};
}

export class Cards extends AbstractCollection<Card, ICard> {
  static definitions = {
    _: {} as ICard,
  };

  static path = 'cards';

  /**
   * Returns all featured cards for this country.
   */
  static featured(country: string) {
    return Countries._.getById(country)
      .Cards.query([where('featured', '==', true)])
      .get();
  }

  /**
   * ! Not implemented.
   * TODO: How to get one featured random card if we don't know the collection length?
   */
  static one(country: string) {
    return Countries._.getById(country)
      .Cards.query([where('featured', '==', true)])
      .get();
  }

  constructor(document: Country) {
    super(document.collection(Cards.path), Card);
  }
}
