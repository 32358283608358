/**
 * Recursively prunes an object from undefined values.
 *
 * @param target Object to be pruned from undefined values
 * @returns {Object} The pruned object
 */
export function pruneObject(target: any): any {
  const isObject = (value: any): boolean => value != null && typeof value === 'object' && Array.isArray(value) !== true;

  if (isObject(target) !== true) {
    return target;
  }

  for (const key in target) {
    /**
     * Check if prop is either an object and recusively
     * check for undefined values.
     */
    if (isObject(target[key]) === true) {
      target[key] = pruneObject(target[key]);
    } else if (target[key] === undefined) {
      delete target[key];
    }

    /**
     * If this leaves an empty object behind, delete it as well.
     */
    if (isObject(target[key]) && Object.keys(target[key]).length === 0) {
      delete target[key];
    }
  }

  return target;
}
