import { AbstractCollection } from '../abstract/Collection';
import { AbstractDocument, Identifiable, Untrackable } from '../abstract/Document';
import type { ICountry } from './Countries.types';
import { ABTests } from './Countries/ABTests';
import { Badges } from './Countries/Badges';
import { Cards } from './Countries/Cards';
import { Costs } from './Countries/Costs';
import { Groups } from './Countries/Groups';
import { Presets } from './Countries/Presets';
import { Suppliers } from './Countries/Suppliers';
import { Tasks } from './Countries/Tasks';
import { TodayTaskFlows } from './Countries/TodayTaskFlows';
import { omit } from 'ramda';

@Identifiable
@Untrackable
export class Country extends AbstractDocument<ICountry> {
  readonly collections = {
    ABTests: new ABTests(this),
    Badges: new Badges(this),
    Cards: new Cards(this),
    Costs: new Costs(this),
    Groups: new Groups(this),
    Presets: new Presets(this),
    Suppliers: new Suppliers(this),
    Tasks: new Tasks(this),
    TodayTaskFlows: new TodayTaskFlows(this),
  };

  /**
   * Returns the user ID of the first admin for this country.
   */
  get adminUser(): Promise<ICountry['admins'][number]> {
    return this.get(true).then((data) => {
      if ((data.admins ?? []).length > 0) {
        return data.admins.shift();
      }

      return this.id !== '*' ? Countries._.getById('*').adminUser : null;
    });
  }

  /**
   * Returns all users IDs who are admin for this country.
   */
  get adminUsers(): Promise<NonNullable<ICountry['admins']>> {
    const promises = [this.get(true)];

    if (this.id !== '*') {
      promises.unshift(Countries._.getById('*').get(true));
    }

    return Promise.all(promises).then((countries) => {
      const result = new Set<ICountry['admins'][number]>();

      for (const country of countries) {
        const users = country.admins ?? [];

        for (const user of users) {
          result.add(user);
        }
      }

      return [...result];
    });
  }

  /**
   * Recursively merges tasks from different countries, returns only active tasks.
   */
  async getActiveTasks(tags?: string[]) {
    if (tags != null) {
      throw new Error(`Not implemented.`);
    }

    return this.collections.Tasks.active();
  }

  /**
   * Recursively merges tasks from different countries.
   */
  async getAllTasks(tags?: string[]) {
    if (tags != null) {
      throw new Error(`Not implemented.`);
    }

    return this.collections.Tasks.all();
  }

  /**
   * Recursively merges costs from different countries, optionally omitting `targets`.
   */
  async getCosts(tags?: string[], withTargets = true) {
    if (tags != null) {
      throw new Error(`Not implemented.`);
    }

    const result = await this.collections.Costs.all();

    if (withTargets !== true) {
      for (const key in result) {
        result[key] = omit(['targets'], result[key]);
      }
    }

    return result;
  }

  /**
   * Recursively merges groups from different countries.
   */
  async getGroups(tags?: string[]) {
    if (tags != null) {
      throw new Error(`Not implemented.`);
    }

    return this.collections.Groups.all();
  }

  async getSupplier() {
    return this.collections.Suppliers.all();
  }
}

export class Countries extends AbstractCollection<Country, ICountry> {
  static definitions = {
    _: {} as ICountry,
  };

  static path = 'countries';

  constructor() {
    super(Countries.path, Country);
  }

  static get _() {
    return new this();
  }
}
