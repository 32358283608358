import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IFile } from './Files.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Untrackable
export class File extends AbstractDocument<IFile> {
  readonly collections = {};
}

export class Files extends AbstractCollection<File, IFile> {
  static definitions = {
    _: {} as IFile,
  };

  static path = 'files';

  constructor(document: Wedding) {
    super(document.collection(Files.path), File);
  }

  static new<M extends typeof Files.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IFile> = {};

    if (key !== '_' && key in Files.definitions) {
      result = (result[key as keyof Omit<typeof Files.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
