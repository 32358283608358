import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import type { Country } from '../Countries';
import type { IABTest } from './ABTests.types';
import { where } from 'firebase/firestore';

@Identifiable
@Timestampable
@Untrackable
export class ABTest extends AbstractDocument<IABTest> {
  readonly collections = {};
}

export class ABTests extends AbstractCollection<ABTest, IABTest> {
  static definitions = {
    _: {} as IABTest,
  };

  static path = 'abtests';

  /**
   * Return all existing AB tests.
   */
  get() {
    return this.query().get(true);
  }

  /**
   * Return all active AB tests.
   */
  get active() {
    return this.query([where('active', '==', true)]);
  }

  constructor(document: Country) {
    super(document.collection(ABTests.path), ABTest);
  }
}
